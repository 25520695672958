body {
  margin: 0;
  font-family:  'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 100;
  background-color: WhiteSmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  white-space: pre;
}

h1, h2, h3, h4, h5{
  font-weight: inherit;
}

i, svg {
  margin-left: 5px;
  margin-right: 5px;
}


/* Uncomment to debug box sizing */
/* * {
  outline: 1px solid #f00 !important;
} */
