.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .table {
    height: 78vh;
    width: 100vw;
  }
  
  .tabs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    overflow: auto;
    scrollbar-width: 0;
    border-bottom: 2px solid #dfdfdf;
  }
  
  .tabs::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  
  .codes {
    display: flex;
    margin: 1rem 0;
    justify-items: flex-start;
  }
  
  .total {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  
  
  /* shift column around */
  /* .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head {
    font-weight: bold;
    resize: horizontal;
    overflow-x: overlay;
    overflow-y: hidden;
    border-right: 1px solid;
  } */
  